import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EscapeHtmlPipe,SafeHtmlPipe } from './escape.html.pipe';
import { FilterPipe, FilterObjectPipe, DaysToDay, AddHyphen, URLDecode } from './filter.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [EscapeHtmlPipe, FilterPipe, FilterObjectPipe,DaysToDay, SafePipe,AddHyphen,URLDecode,SafeHtmlPipe],
  imports: [CommonModule],
  exports: [EscapeHtmlPipe, FilterPipe, FilterObjectPipe,DaysToDay,SafePipe,AddHyphen,URLDecode,SafeHtmlPipe]
})

export class MainPipe { }
