import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
})

@Injectable()
export class FilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: string): any[] {
        if (!items) {
            return [];
        }
        if (!field || !value) {
            return items;
        }
        return items.filter(singleItem =>
            singleItem[field].toLowerCase().includes(value.toLowerCase())
        );
    }
}

@Pipe({
    name: 'filterObject',
})
@Injectable()
export class FilterObjectPipe implements PipeTransform {
    transform(items: any[], field: any, value: string): any[] {
        if (!items) {
            return [];
        }
        if (!field || !value) {
            return items;
        }
        return items.filter(singleItem =>
            singleItem[field.parentObj][field.field].toLowerCase().includes(value.toLowerCase())
        );
    }
}

@Pipe({name: 'days'})
export class DaysToDay implements PipeTransform {
  transform(value: any): String {
    let day:string = "";
    let daysMap = new Map();
    daysMap.set("Sunday","Su");
    daysMap.set("Monday","M");
    daysMap.set("Tuesday","T");
    daysMap.set("Wednesday","W");
    daysMap.set("Thursday","Th");
    daysMap.set("Friday","F");
    daysMap.set("Saturday","Sa");
    var x = value.days.split(",");
    for(let j=0;j<x.length;j++){  
        if(day.length > 0)
        day = day + " | " + daysMap.get(x[j]);
        else
        day = daysMap.get(x[j]);
      }
    return day + " " + value.fromHours +" - " + value.toHours;
  }
}

@Pipe({name: 'isEmpty'})
export class AddHyphen implements PipeTransform {
  transform(value: any):any {
    return (typeof value !== "undefined" && value !== null && value !== '')?value:"-";
  }
}

@Pipe({name: 'urlDecode'})
export class URLDecode implements PipeTransform {
  transform(value: any):any {
    try{
      var url = decodeURIComponent(value);
    }catch(Error){
      return value;
    }
    return url;
  }
}
